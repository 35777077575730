<template>
<v-row justify="center">
    <v-dialog v-model="show" max-width="500px">
        <v-card>
            <v-card-title>
                <span v-if="!userData._id" class="headline">Add New</span>
                <span v-else class="headline">Edit</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field label="Name" v-model="userData.name"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6">
                                <v-text-field label="Surname" v-model="userData.surname"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field label="Email" v-model="userData.email"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field label="Password" type="password" v-model="userData.password"></v-text-field>
                            </v-col>

                            <v-col cols="12" v-if="currentUser.role === 'Super Admin'">
                                <v-text-field label="Role" v-model="userData.role"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click.stop="modal=false">Close</v-btn>
                <v-btn v-if="!userData._id" color="blue darken-1" text @click="updateUser()" :loading="creatingUser">Add</v-btn>
                <v-btn v-else color="blue darken-1" text @click="updateUser()" :loading="creatingUser">Update</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-row>
</template>

<script>
import {mapState} from 'vuex';

export default {
    props: {
        modal: Boolean,
        user: Object,
        saveUser: Function,
        creatingUser: Boolean
    },
    data: () => ({
        userData: {
            user: {
                role: []
            }
        },
    }),
    watch: {
        show(visible) {
            if (visible) {
                this.myUser = {};
                this.userData = this.user;
            } else {
                this.userData = {};
            }
        }
    },
    computed: {
        show: {
            get () {
                return this.modal
            },
            set (value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        },
        ...mapState('auth', { currentUser: 'user' })
    },
    methods: {
        updateUser() {
            this.$emit('saveUser', this.userData)
        }
    },
}
</script>
