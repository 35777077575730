import { defineComponent } from 'vue';
export default defineComponent({
    props: {},
    data() {
        return {
            confirm: false,
        };
    },
    computed: {
        show: {
            get() {
                return this.confirm;
            },
            set(value) {
                if (!value) {
                    this.confirm = false;
                }
            }
        }
    },
    methods: {
        ok() {
            this.confirm = false;
            this.$emit('confirm', true);
        }
    }
});
