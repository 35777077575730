<template>
<div>
    <v-card>
        <v-toolbar flat>
            <v-toolbar-title>
                <h4 style="font-weight:500">Users</h4>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn elevation="0" color="blue darken-1" dark @click="(showUserModal = true) && (userData = {})">
                <v-icon class="mr-3">mdi-account-plus</v-icon>
                Add user
            </v-btn>
        </v-toolbar>
    </v-card>
    <v-container>
        <v-row justify="center">
            <v-col cols="12">
                <v-card>
                    <v-data-table :loading="areUsersLoading" :headers="headers" :items="users">
                        <template v-slot:[`item.actions`]="{ item }">
                            <div style="display:flex; align-items:center; justify-content: flex-end;">
                                <v-icon small class="mr-2" @click="(showUserModal = true) && (userData = item)">mdi-account-edit</v-icon>
                                <confirmation-modal @confirm="deleteItem(item)"></confirmation-modal>
                            </div>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <user-modal @saveUser="saveUser" :creatingUser="creatingUser" :modal="showUserModal" :user="userData" @close="showUserModal = false"></user-modal>
</div>
</template>

<script>
import {
    mapGetters,
    mapState,
    mapActions
} from 'vuex';

import confirmationModal from '@/components/DeleteModal';
import userModal from '@/components/UserModal';
import moment from 'moment';

export default {
    name: 'Users',
    components: {
        confirmationModal,
        userModal
    },
    data() {
        return {
            showUserModal: false,
            userData: {},
            headers: [{
                    text: "Email",
                    align: "start",
                    sortable: false,
                    value: "email"
                },
                {
                    text: "Name",
                    align: "start",
                    value: "name",
                    sortable: true
                },
                {
                    text: "Surname",
                    align: "start",
                    value: "surname",
                    sortable: true
                },
                {
                    text: "Created At",
                    align: "end",
                    value: "createdAt",
                    sortable: true
                },
                {
                    text: "Actions",
                    align: "end",
                    value: "actions",
                    sortable: true
                },
            ],
        }
    },
    created() {
        this.fetchUsers({
            query: {
                $limit: 1000
            }
        });
    },
    computed: {
        ...mapState('auth', {
            user: 'payload'
        }),
        ...mapState('users', {
            areUsersLoading: 'isFindPending',
            creatingUser: 'isCreatePending',
        }),
        ...mapGetters('users', {
            findUsersInStore: 'find'
        }),
        users() {
            return this.findUsersInStore({
                query: {
                    $limit: 1000,
                    $sort: {
                        createdAt: -1
                    }
                }
            }).data.map(function (user) {
                return {
                    _id: user._id,
                    email: user.email,
                    name: user.name,
                    surname: user.surname,
                    createdAt: moment(String(user.createdAt)).format('MM.DD.Y HH:mm')
                }
            })
        },
    },
    methods: {
        format_date(value) {
            if (value) {
                return moment(String(value)).format('LL')
            }
        },
        ...mapActions('users', {
            fetchUsers: 'find',
            removeUser: 'remove',
            createUser: 'create',
            updateUser: 'patch',
        }),
        saveUser(item) {
            let promise, successMessage;
            if (item._id) {
                promise = this.updateUser([item._id, item, {}]);
                successMessage = 'Record updated';
            } else {
                promise = this.createUser(item);
                successMessage = 'Record created';
            }
            promise.then(() => {
                this.$toasted.global.success({
                    message: successMessage
                });
                this.showUserModal = false;
            }).catch(errors => {
                this.$toasted.global.error({
                    message: errors.message
                });
            })
        },
        deleteItem(item) {
            this.removeUser(item._id).then(() => {
                this.$toasted.global.success({
                    message: 'Record removed'
                });
            })
        }
    },

}
</script>

<style>

</style>
